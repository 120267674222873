<template>
  <v-card>
    <v-card-title>Submitted Information</v-card-title>
    <v-card-text>
      <v-card v-for="({ major, advisor, primary, conc, advisorItems }, index) of program" :key="'prog-' + major" class="mb-2">
        <v-card-text>
          <v-row>
            <v-col class="pb-0">
              <v-combobox :value="major" :items="majorItems" :label="'Major' + (program.length > 1 ? ' ' + (index + 1) : '')" readonly>
                <template v-slot:append>&nbsp;</template>
              </v-combobox>
            </v-col>
            <v-col class="pb-0">
              <v-select v-if="advisorEditable" :value="advisor" :items="advisorItems" :hint="advisor === '' ? 'No Advisor Preference' : ''" label="Advisor" persistent-hint @change="(advisor) => $emit('update', { type: 'program', index, advisor })">
                <template v-slot:append v-if="!advisorEditable">
                  <v-icon>&nbsp;</v-icon>
                </template>
              </v-select>
              <v-text-field v-else :value="advisor" label="Requested Advisor" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="primary && major === 'IDS'" class="pt-0">
              <v-text-field :value="primary" label="Primary Concentration" readonly></v-text-field>
            </v-col>
            <v-col v-if="conc.length > 0" class="pt-0">
              <v-select :value="conc" :items="conc" label="Concentrations" multiple chips small-chips readonly>
                <template v-slot:append>&nbsp;</template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="minor.length > 0" class="mb-2">
        <v-card-text>
          <v-row>
            <v-col>
              <v-select :value="minor" :items="minorItems" :label="'Minor' + (minor.length > 1 ? 's' : '')" multiple chips small-chips readonly>
                <template v-slot:append>&nbsp;</template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-for="({ cert, advisor, advisorItems }, index) in certificate" :key="'cert-' + cert" class="mb-2">
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field :value="cert" :label="'Certificate' + (certificate.length > 1 ? ' ' + (index + 1) : '')" readonly></v-text-field>
            </v-col>
            <v-col>
              <v-select v-if="advisorEditable" :value="advisor" :items="advisorItems" :hint="advisor === '' ? 'No Advisor Preference' : ''" label="Advisor" persistent-hint @change="(advisor) => $emit('update', { type: 'certificate', index, advisor })"></v-select>
              <v-text-field v-else :value="advisor" label="Requested Advisor" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-list>
        <v-list-item v-if="p12">
          <v-list-item-avatar>
            <v-icon color="success">fas fa-check-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-subtitle style="white-space: normal;">P-12 or Secondary (grades 6-12) Education - the five year secondary certification program including MAT degree</v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-if="isEDSTMajor">
          <v-list-item-content>
            <v-list-item-subtitle style="white-space: normal">Planning middle grades certification and completing the fifth-year Master of Arts in Teaching degree at Covenant. Content fields:</v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-chip v-for="val in contentFields" :key="'contentFeild-' + val" small label outlined>{{ val }}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions v-if="data.status === 'Processed'">
      <v-btn color="info" @click="returnSubmission()">Make further changes</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { advisors, concentrations, IDSPrimaryConcentrations, certAdvisors, certificates } from './options'

export default {
  props: {
    advisorEditable: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const lookup = reactive({})
    const majorItems = ref([])
    const minorItems = ref([])
    const program = ref([])
    const minor = ref([])
    const certificate = ref([])
    const p12 = ref(false)
    const contentFields = ref([])
    const isEDSTMajor = computed(() => program in props.data && Array.isArray(props.data.program) && props.data.program.reduce((prev, { major }) => prev || major === 'EDST', false))

    onMounted(async () => {
      // Load the major options
      const { data: majorData } = await root.$feathers.service('forms/select-options').find({ query: { name: 'Major Code' } })
      const { options: majors } = await root.$feathers.service('forms/select-options').get(majorData[0].value)
      majorItems.value = majors
      // Load the minor options
      const { data: minorData } = await root.$feathers.service('forms/select-options').find({ query: { name: 'Minor Code' } })
      const { options: minors } = await root.$feathers.service('forms/select-options').get(minorData[0].value)
      minorItems.value = minors
    })

    watch(() => props.data, async () => {
      if (!('program' in props.data)) return
      program.value = []
      for (const { major, advisor, primary, conc: concCodes } of props.data.program) {
        const conc = concCodes.map((val) => concentrations[major].reduce((prev, { value, text }) => value === val ? text : prev, ''))
        const obj = { major: lookup[major] || major, conc, advisor: '**No Preference**' }
        if (props.advisorEditable) {
          obj.advisor = advisor
          obj.advisorItems = advisors[major]
        } else if (advisor !== '') {
          const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: advisor } })
          obj.advisor = dirData[0].name.last + ', ' + dirData[0].name.first
        }
        if (primary) {
          obj.primary = IDSPrimaryConcentrations.reduce((prev, { value, text }) => value === primary ? text : prev, '')
        }
        program.value.push(obj)
      }
      minor.value = props.data.minor
      certificate.value = []
      for (const { cert, advisor } of props.data.certificate) {
        const obj = { cert: certificates.reduce((prev, { value, text }) => value === cert ? text : prev, ''), advisor: '**No Preference**' }
        if (props.advisorEditable) {
          obj.advisor = advisor
          obj.advisorItems = certAdvisors[cert]
        } else if (advisor !== '') {
          const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: advisor } })
          obj.advisor = dirData[0].name.last + ', ' + dirData[0].name.first
        }
        certificate.value.push(obj)
      }
      p12.value = props.data.p12
      contentFields.value = props.data.contentField
    })

    async function returnSubmission () {
      const newData = await root.$feathers.service('student/major-change').patch(props.data._id, {
        status: 'Returned',
        $push: { timeline: {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: 'Reopened to make further changes',
          icon: 'fas fa-undo',
          color: 'info',
          visibleToStudent: true
        } }
      })
      emit('update', newData)
    }

    return {
      user,
      majorItems,
      minorItems,
      program,
      minor,
      certificate,
      p12,
      contentFields,
      isEDSTMajor,
      returnSubmission
    }
  }
}
</script>
