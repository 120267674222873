var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Submitted Information")]),_c('v-card-text',[_vm._l((_vm.program),function(ref,index){
var major = ref.major;
var advisor = ref.advisor;
var primary = ref.primary;
var conc = ref.conc;
var advisorItems = ref.advisorItems;
return _c('v-card',{key:'prog-' + major,staticClass:"mb-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-combobox',{attrs:{"value":major,"items":_vm.majorItems,"label":'Major' + (_vm.program.length > 1 ? ' ' + (index + 1) : ''),"readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" ")]},proxy:true}],null,true)})],1),_c('v-col',{staticClass:"pb-0"},[(_vm.advisorEditable)?_c('v-select',{attrs:{"value":advisor,"items":advisorItems,"hint":advisor === '' ? 'No Advisor Preference' : '',"label":"Advisor","persistent-hint":""},on:{"change":function (advisor) { return _vm.$emit('update', { type: 'program', index: index, advisor: advisor }); }},scopedSlots:_vm._u([(!_vm.advisorEditable)?{key:"append",fn:function(){return [_c('v-icon',[_vm._v(" ")])]},proxy:true}:null],null,true)}):_c('v-text-field',{attrs:{"value":advisor,"label":"Requested Advisor","readonly":""}})],1)],1),_c('v-row',[(primary && major === 'IDS')?_c('v-col',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"value":primary,"label":"Primary Concentration","readonly":""}})],1):_vm._e(),(conc.length > 0)?_c('v-col',{staticClass:"pt-0"},[_c('v-select',{attrs:{"value":conc,"items":conc,"label":"Concentrations","multiple":"","chips":"","small-chips":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" ")]},proxy:true}],null,true)})],1):_vm._e()],1)],1)],1)}),(_vm.minor.length > 0)?_c('v-card',{staticClass:"mb-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"value":_vm.minor,"items":_vm.minorItems,"label":'Minor' + (_vm.minor.length > 1 ? 's' : ''),"multiple":"","chips":"","small-chips":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" ")]},proxy:true}],null,false,3212828659)})],1)],1)],1)],1):_vm._e(),_vm._l((_vm.certificate),function(ref,index){
var cert = ref.cert;
var advisor = ref.advisor;
var advisorItems = ref.advisorItems;
return _c('v-card',{key:'cert-' + cert,staticClass:"mb-2"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":cert,"label":'Certificate' + (_vm.certificate.length > 1 ? ' ' + (index + 1) : ''),"readonly":""}})],1),_c('v-col',[(_vm.advisorEditable)?_c('v-select',{attrs:{"value":advisor,"items":advisorItems,"hint":advisor === '' ? 'No Advisor Preference' : '',"label":"Advisor","persistent-hint":""},on:{"change":function (advisor) { return _vm.$emit('update', { type: 'certificate', index: index, advisor: advisor }); }}}):_c('v-text-field',{attrs:{"value":advisor,"label":"Requested Advisor","readonly":""}})],1)],1)],1)],1)}),_c('v-list',[(_vm.p12)?_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-check-circle")])],1),_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"}},[_vm._v("P-12 or Secondary (grades 6-12) Education - the five year secondary certification program including MAT degree")])],1):_vm._e(),(_vm.isEDSTMajor)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticStyle:{"white-space":"normal"}},[_vm._v("Planning middle grades certification and completing the fifth-year Master of Arts in Teaching degree at Covenant. Content fields:")]),_c('v-list-item-subtitle',_vm._l((_vm.contentFields),function(val){return _c('v-chip',{key:'contentFeild-' + val,attrs:{"small":"","label":"","outlined":""}},[_vm._v(_vm._s(val))])}),1)],1)],1):_vm._e()],1)],2),(_vm.data.status === 'Processed')?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.returnSubmission()}}},[_vm._v("Make further changes")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }